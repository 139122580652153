import * as React from "react"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FlickrThumbnail from "../components/flickr/flickrthumbnail"

import * as style from "../pages/index.module.scss"

const Gallery = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <Seo title={frontmatter.title} pathname={frontmatter.slug} />
      {/* Helmet is put after SEO so correct body class is added.  */}
      <Helmet bodyAttributes={{ class: "gallery" }} />
      <>
        <div className={style.content}>
          <h1>{frontmatter.title}</h1>
          <p>
            I have not updated my Flickr albums yet, so the gallery pages will
            only contain the thumbnails from the individual category. If you
            click on a photo, it will open a new tab or window in Flickr.
          </p>
        </div>
        <section className={style.gallery_wrapper}>
          <FlickrThumbnail cat={frontmatter.tag} />
        </section>
      </>
    </Layout>
  )
}

export default Gallery

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        category
        slug
        tag
        title
      }
    }
  }
`
